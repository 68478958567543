<template lang="html">
  <header id="header">
    <div class="header-content" id="header-content">

      <!-- Header menu -->
      <div class="header-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.png">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
              <i class="fal fa-bars"></i>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <!-- <b-navbar-nav class="mb-2 mb-lg-0">
              </b-navbar-nav> -->

              <b-navbar-nav class="ml-auto">
                <b-nav-item class="simple-item" to="/simplepaz">SimplePaz</b-nav-item>
                <b-nav-item class="simple-item" to="">ProPaz</b-nav-item>
                <b-nav-item class="simple-item" to="/olimpo">Olimpo</b-nav-item>
                <b-nav-item class="simple-item" target="_blank" href="https://lapazprevisionfamiliar.com/#/empresa">Empresa</b-nav-item>
                <b-nav-item class="simple-item" target="_blank" href="https://lapazprevisionfamiliar.com/#/contacto">Contacto</b-nav-item>
                <b-nav-item class="simple-item button-item" to="">Clientes <i class="far fa-user icon"></i></b-nav-item>
                <!-- <b-nav-item class="cart-item" to="/cart">
                  <div>
                    <i class="far fa-shopping-cart icon"></i> <span class="label">Mi carrito</span> <i class="num">0</i>
                  </div>
                </b-nav-item>

                <b-nav-item-dropdown class="user-item" right>
                  <template #button-content>
                    <span><i class="far fa-user icon"></i> <span class="label">Mi cuenta</span> </span>
                  </template>
                  <b-dropdown-item to="/login">Mi cuenta</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
                </b-nav-item-dropdown> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

    </div>
  </header>
</template>

<script>
export default {
  data(){
    return{
    }
  },

  methods: {
    getHeaderSize(e) {
      var headerHeight = document.getElementById("header-content").offsetHeight;
      document.getElementById("header").style.height = headerHeight+'px';
    }
  },

  mounted() {
    setTimeout(()=> {
      this.getHeaderSize();
    }, 500)

  },

  watch: {
  },

  created() {
    window.addEventListener("resize", this.getHeaderSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeaderSize);
  },
}
</script>
